import SwitchNetworkButton from 'components/header/SwitchNetworkButton';
import { useMounted } from 'lib/hooks/useMounted';
import { isSupportedChain } from 'lib/utils/chains';
import { useRouter } from 'next/router';
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi';
import ChainSelect from '../common/ChainSelect';
import WalletIndicatorDropdown from './WalletIndicatorDropdown';

interface Props {
  menuAlign?: 'left' | 'right';
  size?: 'sm' | 'md' | 'lg' | 'none';
  style?: 'primary' | 'secondary' | 'tertiary' | 'none';
  className?: string;
}

const WalletIndicator = ({ menuAlign, size, style, className }: Props) => {
  const isMounted = useMounted();
  const { address: account } = useAccount();
  const { switchNetworkAsync } = useSwitchNetwork();
  const { chain } = useNetwork();
  const router = useRouter();

  if (!isMounted) return null;

  const isSupport = isSupportedChain(chain?.id);

  const onSelect = async (chainId) => {
    try {
      await switchNetworkAsync(chainId);
      await router.replace({ query: { ...router.query, chainId } }, undefined, { shallow: false });
      router.reload();
    } catch (error) {}
  };

  return (
    <div className="flex gap-2">
      {!isSupport && account && <SwitchNetworkButton size="md" />}
      {isSupport && account && chain && (
        <ChainSelect instanceId="global-chain-select" onSelect={onSelect} selected={chain.id} menuAlign={menuAlign} />
      )}
      <WalletIndicatorDropdown size={size} style={style} className={className} />
    </div>
  );
};

export default WalletIndicator;
