import Href from 'components/common/Href';
import WalletIndicator from 'components/header/WalletIndicator';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import MobileMenu from './MobileMenu';
import SearchBar from './SearchBar';

interface Props {
  searchBar?: boolean;
}

const Header = ({ searchBar = true }: Props) => {
  const { t } = useTranslation();
  // console.log(`Header:${process.env.NEXT_PUBLIC_CHAIN_ID}`);
  // console.log(`Heade2:${process.env.NEXT_PUBLIC_NODE_URLS}`);
  const logoLinkUrl =
    process.env.NEXT_PUBLIC_CHAIN_ID === '5001'
      ? 'https://explorer.testnet.mantle.xyz/'
      : 'https://explorer.mantle.xyz/';

  return (
    <header className="header-bg flex flex-col relative p-4 lg:px-8 gap-4">
      <div className="flex justify-between items-center gap-8">
        <div className="lg:flex justify-start items-center gap-4 w-2/5 flex-wrap">
          <Href href={logoLinkUrl} underline="none" className="flex" router>
            <Image
              src="/assets/images/mantle-logo__dark.svg"
              alt="Mantle logo"
              height="40"
              width="155"
              className="shrink-0"
              priority
            />
          </Href>
          {/* <DonateButton size="md" /> */}
          {/* <NavLink to="/faq" text={t('common:nav.faq')} /> */}
          {/* <NavLink to="/extension" text={t('common:nav.extension')} /> */}
          {/* <NavLink to="/exploits" text={t('common:nav.exploits')} /> */}
        </div>
        <div className="flex lg:justify-center grow shrink-0"></div>
        <div className="hidden lg:flex justify-end w-2/5 gap-2">
          <WalletIndicator />
        </div>
        <div className="flex lg:hidden justify-end">
          <MobileMenu />
        </div>
      </div>
      <hr style={{ borderTop: '1px solid #091312' }}></hr>
      <div className="max-w-7xl w-full mx-auto px-4 py-4 lg:px-8">
        {searchBar && (
          <div className="flex justify-start">
            <SearchBar />
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
