import AddressSearchBox from 'components/common/AddressSearchBox';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useState } from 'react';

const SearchBar = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const [value, setValue] = useState<string>('');

  return (
    <div className="flex flex-col lg:flex-col gap-2 lg:gap-4 flex justify-start lg:w-2/4">
      <h1>Token Approval Checker</h1>
      <h5>Review and revoke your token approvals for any dApp on Mantle.</h5>
      <AddressSearchBox
        onSubmit={() => router.push(`/address/${value}`)}
        onChange={(ev) => setValue(ev.target.value.trim())}
        value={value}
        placeholder={t('common:nav.search')}
        className="w-full max-w-5xl text-base sm:text-lg"
      />
    </div>
  );
};

export default SearchBar;
