import Button from 'components/common/Button';
import { useRouter } from 'next/router';
import { useAsyncCallback } from 'react-async-hook';
import { twMerge } from 'tailwind-merge';
import { useAccount, useSwitchNetwork } from 'wagmi';

interface Props {
  text?: string;
  size?: 'sm' | 'md' | 'lg' | 'none';
  style?: 'primary' | 'secondary' | 'tertiary' | 'none';
  className?: string;
}

const SwitchNetworkButton = ({ size, style, className, text }: Props) => {
  const { switchNetworkAsync } = useSwitchNetwork();
  const { address: account } = useAccount();
  const switchChainID = Number(process.env.NEXT_PUBLIC_CHAIN_ID);
  const router = useRouter();
  const isConnected = !!account;

  const classes = twMerge(className, 'font-bold mx-auto main-bg border-none rounded-full hover:bg-teal-700');
  const { execute, loading } = useAsyncCallback(() => switchNetworkAsync(switchChainID || 5000));

  const handleClick = async () => {
    try {
      if (isConnected) {
        await execute();
        router.reload();
      } else {
        router.push('/');
      }
    } catch {
      // ignored
    }
  };
  return (
    <Button style={style ?? 'primary'} size={size ?? 'lg'} loading={loading} className={classes} onClick={handleClick}>
      {text ?? 'Switch Network'}
    </Button>
  );
};

export default SwitchNetworkButton;
